<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Forgot Password</h3>                           
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <status v-model:status_data=status_data></status>
                            </div>
                        </div>
                    </div>

                    <form id="form" action="#" @submit.prevent="handleSubmit()">
                        <div class="border-bottom-0 p-4 pt-2">
                            <div class="form-row mt-4">
                                <div class="input-group">
                                    <span class="input-group-text border-right-0" @click="seePassword()">
                                        <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                    </span>
                                    
                                    <input id="email" type="email" class="form-control no-border" v-model="data.email" placeholder="Enter your Email" required>
                                </div>
                                <small>Enter the email attached to your account</small>
                            </div>

                            <div class="row" id="login">
                                <div class="col">
                                    <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px">Send</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <small class="text-muted text-start">
                                    Go back to <router-link class="text-orange fw-bold text-decoration-none" :to="'/login'">Login</router-link>
                                </small>
                            </div>
                        </div>
                    </form>
                    
                </div>  
            </div>
        </div>

        <div class="container-fluid">
            <p class="text-center pt-2">Don't have an account? <router-link :to="'/register'" class="text-orange text-decoration-none">Create One</router-link></p>
        </div>

    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';
    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    } 
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'Login',
        components: {
            Status
        },
        data() {
            return {
                data: {
                    email: '',
                },
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null
                },
                loading: false
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true
                this.axios.post('api/forgot-password', this.data).then((response) => {
                    console.log(response);
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = "We've sent an email to you with instructions on how to reset your password."
                }).catch((errors) => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    this.status_data.error_message = errors.message

                    console.log(errors);
                })      
            }
        }
    }
</script>